import React from "react";
import { Link, graphql } from "gatsby";
import { xor, isEmpty } from "lodash-es";
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as inv_rectangle from "../assets/animations/masks/inv_rectangle.json";

import Layout from "../components/layout/Layout";
import { Button, ButtonSymbolHover } from "../components/Button";
import CustomerCases from "../components/CustomerCases";
import Transcript from "../components/Transcript";

import { slug, getDefinedTermPopUpItem } from "../helpers";
import { CustomerDisplayItem } from "../templates/customers-template";
import { PageLinkList } from "../components/PageLinkList";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";

export default ({ pageContext, data, location }) => {
	const {
		ingress,
		heroImage,
		customerReferences,
		customerReferenceImages,
		content,
		createdAt,
		updatedAt,
		metaTags,
		color,
		pageLinkLists,
		pageLinkListHeader
	} = data.contentfulCustomerType;

	const moreCustomerReferenceImages = !isEmpty(customerReferenceImages) && customerReferenceImages.length > 2 ? customerReferenceImages.concat(customerReferenceImages) : null;

	const otherCustomerType = data.allContentfulCustomerType.edges;

	const settings = {
		// lazyLoad: 'progressive',
		draggable: false,
		autoplay: true,
		dots: false,
		arrows: false,
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		centerMode: true,
		pauseOnHover: false,
		speed: 5000,
		autoplaySpeed: 5000,
		cssEase: "linear",
		responsive: [
			{
				breakpoint: 464,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	};

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
			slidesToSlide: 4 // optional, default to 1.

		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
			slidesToSlide: 2 // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1 // optional, default to 1.
		}
	};

	const colors = ["green", "pink", "blue"];
	const seoData = {
		updatedAt: updatedAt,
		createdAt: createdAt,
		title: pageContext.name,
		author: "Frends team",
		metaTags: metaTags
	}

	const [shouldPlayFeatured, setShouldPlayFeatured] = React.useState([]);

	const handleHover = () => {
		setShouldPlayFeatured(shouldPlay => xor(shouldPlay, [0]));
	}

	const defaultOptions = {
		loop: false,
		autoplay: false,
		animationData: inv_rectangle.default,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		},
	};

	const pageColor = color !== null ? color : pageContext.color;

	const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);

	return (
		<Layout title={`Industries - ${pageContext.name}`} isCustomerType={true} seoData={seoData} location={location}>
			<main id="content">
				<section className={`hero is-fullheight-with-navbar`}>
					<div className="hero-body" style={{ position: "relative" }}>
						<div className="content">
							<div className="columns is-mobile">
								<div className="column is-10-widescreen is-offset-1-widescreen is-12-mobile">
									<div className="columns is-mobile is-vcentered is-multiline">
										<div className="column is-8-desktop has-text-centered-mobile">
											<h1 className="title font-variable line-height-100 size-100 is-white text-stroke-black font-width-variation-870" style={{ marginBottom: "-15px" }}>For</h1><br />
											<h2 className="title font-variable line-height-100 size-100 is-black" style={{ marginTop: "0px" }}>{pageContext.name}</h2>
											<div className="columns">
												<div className="column is-10-desktop is-12">
													<div className="padding-y-20" dangerouslySetInnerHTML={{ __html: ingress.childMarkdownRemark.html }} />
												</div>
											</div>
											<ButtonSymbolHover type="outlined" to="/trial" text="start 30-day trial" color={pageColor} />
										</div>
										<div className="column is-4-desktop is-hidden-mobile is-hidden-desktop-only has-text-centered">
											<GatsbyImage
												image={getImage(heroImage)}
												style={{ borderRadius: "50%", padding: "20px" }}
												alt={heroImage.title} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className={`section is-medium is-${pageColor}-bg`}>
					<div className="container content">
						<div className="columns">
							<ContentHTMLWithHoverPopUps
                contentHTML={content.childMarkdownRemark.html} 
                popUpItems={definedTermsPopUpItems}
								className="column is-three-fifths is-offset-one-fifth"
              />
						</div>
						<div className="columns">
							<div className="column is-three-fifths is-offset-one-fifth">
								<Button customStyles={{ margin: "10px 0", height: "50px" }} textClass="size-20" type="outlined" text="contact us" color="purple" to="/ask-us-anything" />
							</div>
						</div>
					</div>
				</section>
				{!isEmpty(customerReferences) && (
					customerReferences.length === 1 ? (
						<CustomerDisplayItem
							customer={customerReferences.map((x) => ({ node: x }))[0]}
							handleHover={handleHover}
							defaultOptions={defaultOptions}
							shouldPlayFeatured={shouldPlayFeatured}
							index={0}
						/>
					)
						: <CustomerCases
							usingCards={false}
							customers={customerReferences}
							seeMoreLink={{ title: 'see more cases', url: '/customers' }}
						/>
				)}
				{!isEmpty(moreCustomerReferenceImages) && (
					<section className="section">
						<Slider {...settings}>
							{moreCustomerReferenceImages.map((logo, idnx) => (
								<img src={logo.file.url} key={idnx} width={logo.file.details.image.width} height={logo.file.details.image.height}
									className="padding-x-10" alt={logo.title} loading="lazy" />
							))}
						</Slider>
					</section>
				)}
				{pageLinkLists !== null && (
					<section className={`section is-medium`}>
						<div className="container content">
							<div className="columns">
								<div className="column is-three-fifths is-offset-one-fifth">
									<PageLinkList lists={pageLinkLists} header={pageLinkListHeader} />
								</div>
							</div>
						</div>
					</section>
				)}
				<section className="section">
					<div className="container content">
						<div className="columns has-text-centered padding-y-40">
							{otherCustomerType.map((x, i) => {
								const getColor = (i) => colors[i] ? colors[i] : colors[Math.floor(Math.random() * colors.length)];
								const color = getColor(i);
								return (
									<div className="column" key={i}>
										<Link to={`/industries/${slug(x.node.name)}`} className={`is-${color} is-${color}-border-bottom read-more`}>Frends for {x.node.name}</Link>
									</div>
								)
							})}
							<div className="column">
								<Link to="/customers" className="is-black is-black-border-bottom read-more">see all cases</Link>
							</div>
						</div>
					</div>
				</section>
				<Transcript title="fill in the form" subtitle="and see what we can do for you" link="/trial" textColor="white" iconColor={pageColor} backgroundImage={data} />
			</main>
		</Layout>
	);
}

export const query = graphql`query ($contentful_id: String!, $contentful_content_vocabulary_item_slugs: [String]) {
  contentfulCustomerType(contentful_id: {eq: $contentful_id}) {
    metaTags
    createdAt
    updatedAt
    name
    color
    pageLinkListHeader
    pageLinkLists {
      linkList {
        ... on ContentfulWebinarV2 {
		__typename
          id
          title
          webinarUrl
          webinarFreeText {
            childMarkdownRemark {
              excerpt
            }
          }
          webinarImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulServicesSubpage {
			__typename
          id
          title
          slug
          ingress {
            childMarkdownRemark {
              excerpt
            }
          }
          heroImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulLanding {
			__typename
          id
          title
          slug
          ingress {
            childMarkdownRemark {
              excerpt
            }
          }
          heroImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulCustomerType {
			__typename
          id
          name
          slug
          ingress {
            childMarkdownRemark {
              excerpt
            }
          }
          heroImage {
            gatsbyImageData(width: 400, height: 400)
          }
        }
        ... on ContentfulCustomer {
			__typename
          id
          name
          description
          slug
          heroImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulCoSellPartner {
			__typename
          id
          name
          slug
          slogan
        }
        ... on ContentfulArticle {
			__typename
          id
          title
          articleUrl
          subtitle
          heroImage {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
      title
    }
    ingress {
      childMarkdownRemark {
        html
      }
    }
    heroImage {
      gatsbyImageData(width: 600, height: 600)
      title
    }
    customerReferences {
      name
      description
      category
      color
	  slug
      heroImage {
        gatsbyImageData(width: 1024)
        title
      }
      logo {
        file {
		url
          details {
            image {
              height
              width
            }
          }
        }
        title
      }
      image {
        gatsbyImageData(width: 380)
        title
      }
    }
    customerReferenceImages {
      file {
		  url
        details {
          image {
            height
            width
          }
        }
      }
      title
    }
    content {
      childMarkdownRemark {
        html
      }
    }
  }
  allContentfulCustomerType(
    sort: {fields: appearanceOrder}
    limit: 3
    filter: {contentful_id: {ne: $contentful_id}, node_locale: {eq: "en-US"}}
  ) {
    edges {
      node {
        name
      }
    }
  }
  bg2: file(relativePath: {eq: "images/new_purple_background2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 1918, placeholder: NONE, layout: FIXED)
    }
  }
  allContentfulDefinedTerm(
    filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: "en-US"}}
    ) {
    nodes {
        slug
        title
        description {
            childMarkdownRemark {
                html
                excerpt
            }
        }
    }
  }
}
`
